<template>
  <div class="w" style="padding-bottom: 100px;padding-top: 100px;">
      <el-dialog
      :visible.sync="dialogVisible"
      :before-close="CancelPayment"
      width="400px"
      custom-class="qrcode-dialog"
      title="支付"
    >
      <div style="text-align:center">
          使用 
          <span  class="pay-for-method-1">微信</span>
          扫一扫二维码进行支付
      </div>
      <div class="qrcode-container">
        <img :src="qrcodeSrc" alt="二维码" class="qrcode-image"/>
      </div>
      <div style="text-align:center">注：若二维码过期失效，请刷新页面重新进入支付！</div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="CancelPayment">取 消</el-button>
    </span>
    </el-dialog>
    <y-shelf title="支付订单">
      <div slot="content">
        <div class="box-inner order-info">
          <h3>提交订单成功</h3>
          <p class="payment-detail">请在 <span>24 小时内</span>完成支付，超时订单将自动取消。</p>
        </div>
        <!-- <div class="pay-info">
          <span style="color:red">*</span> 昵称：<el-input v-model="nickName" placeholder="请输入您的昵称" @change="checkValid" :maxlength="maxLength" class="input"></el-input><br>
          <span style="color:red">*</span> 捐赠金额：<el-select class="money-select" v-model="moneySelect" placeholder="请选择支付金额" @change="changeSelect">
            <el-option label="￥0.10 我是穷逼" value="0.10"></el-option>
            <el-option label="￥1.00 支付测试" value="1.00"></el-option>
            <el-option label="￥5.00 感谢捐赠" value="5.00"></el-option>
            <el-option label="￥10.00 感谢大佬" value="10.00"></el-option>
            <el-option label="自定义 随意撒币" value="custom"></el-option>
          </el-select><br>
          <div v-if="moneySelect === 'custom'"><span style="color:red">*</span> 输入金额：<el-input v-model="money" placeholder="请输入捐赠金额(最多2位小数，不得低于0.1元)" @change="checkValid" :maxlength="maxLength" class="input" style="margin-left:10px"></el-input><br></div>
          <span style="color:red">*</span> 通知邮箱：<el-input v-model="email" placeholder="支付审核结果将以邮件方式发送至您的邮箱" @change="checkValid" :maxlength="maxLength" class="input" style="margin-left:10px"></el-input><br>
          &nbsp;&nbsp; 留言：<el-input v-model="info" placeholder="请输入您的留言内容" :maxlength="maxLength" class="input"></el-input>
        </div> -->
        <!--支付方式-->
        <div class="pay-type">
          <div class="p-title">支付方式</div>
          <div class="pay-item">
            <!-- <div :class="{active:payType==1}" @click="payType=1"><img src="/static/images/alipay@2x.png" alt=""></div> -->
            <div :class="{active:payType==2}" @click="payType=2"><img src="../../assets/image/weixinpay@2x.png" alt=""></div>
            <!-- <div :class="{active:payType==3}" @click="payType=3"><img src="/static/images/qqpay.png" alt=""></div> -->
          </div>
        </div>

        <div>
          <div class="box-inner">
            <div>
              <span>
                订单金额：
              </span>
              <em><span>¥</span>{{checkPrice.toFixed(2)}}</em>
              <span>
                实际应付金额：
              </span>
              <em><span>¥</span>{{checkPrice.toFixed(2)}}</em>
              <y-button :text="payNow"
                        :classStyle="submit?'main-btn':'disabled-btn'"
                        style="width: 120px;height: 40px;font-size: 16px;line-height: 38px"
                        @btnClick="paySuc()"
              ></y-button>
            </div>
          </div>
        </div>

      </div>
    </y-shelf>
    <!--地址信息-->
    <div class="p-msg w">
      <div class="confirm-detail">
        <div class="info-title">收货信息</div>
        <p class="info-detail">姓名：{{$store.state.msg.receivername}}</p>
        <p class="info-detail">联系电话：{{$store.state.msg.receivermobile}}</p>
        <p class="info-detail">详细地址：{{$store.state.msg.receiveraddress}}</p></div>
    </div>
    <div class="confirm-table-title">
      <span class="name">商品信息</span>
      <div>
        <span class="price">单价</span>
        <span class="num">数量</span>
        <span class="subtotal">小计</span>
      </div>
    </div>
    <!--商品-->
    <div class="confirm-goods-table">
      <div class="cart-items" v-for="(item,i) in $store.state.GoodsList" :key="i">
        <div class="name">
          <div class="name-cell ellipsis">
            <a @click="goodsDetails(item.proid)" title="" target="_blank">{{item.proName || item.name}}</a>
          </div>
        </div>
        <div class="n-b">
          <div class="price">¥ {{item.price}}</div>
          <div class="goods-num">{{item.proNumber}}</div>
          <div class="subtotal">
            <div class="subtotal-cell"> ¥ {{item.price * item.proNumber}}<br></div>
          </div>
        </div>
      </div>
    </div>
    <!--合计-->
    <div class="order-discount-line">
      <p style="font-size: 14px;font-weight: bolder;"> <span style="padding-right:47px">商品总计：</span>
        <span style="font-size: 16px;font-weight: 500;line-height: 32px;">¥ {{checkPrice}}</span>
      </p>
      <p><span style="padding-right:30px">运费：</span><span style="font-weight: 700;">+ ¥ 0.00</span></p>
    </div>
  </div>
</template>
<script>
  import axios from "axios";
  import YShelf from '@/components/shelf.vue'
  import YButton from '@/components/YButton'
  import { selectNativePay } from '../../server/api'
  // import { getStore, setStore } from '/utils/storage'
  export default {
    data () {
      return {
        time: null, //定义setInterval的定时器名称
        setTime: null, //定义setTimeout的定时器名称
        form:{},
        dialogVisible:false,
        qrcodeSrc: '',
        payType: 2,
        addList: {},
        addressId: 0,
        proid: '',
        num: '',
        userId: '',
        orderTotal: 0,
        userName: '',
        tel: '',
        streetName: '',
        payNow: '立刻支付',
        submit: false,
        nickName: '',
        money: '1.00',
        info: '',
        email: '',
        orderId: '',
        type: '',
        moneySelect: '1.00',
        isCustom: false,
        maxLength: 30
      }
    },
    computed: {
      // 选中的总价格
      checkPrice () {
        let totalPrice = 0
        this.$store.state.GoodsList && this.$store.state.GoodsList.forEach(item => {
            if(item.proNumber){
            totalPrice += (item.proNumber * item.price)
            }else{
              totalPrice += (item.productNum * item.price)
            }
        })
        return totalPrice
      }
    },
    methods: {
      checkValid () {
          this.submit = true
      },
      messageFail (m) {
        this.$message.error({
          message: m
        })
      },
      changeSelect (v) {
        if (v !== 'custom') {
          this.money = v
        } else {
          this.isCustom = true
          this.money = ''
        }
        this.checkValid()
      },
      goodsDetails (id) {
        window.open(window.location.origin + '#/shopping/goodsDetails?proid=' + id)
      },
      _getOrderDet (form) {
        if(form){
          this.submit = true
        }
      },
      //清除定时器
      clearTimer() {
        clearInterval(this.time)
        clearTimeout(this.setTime)
        this.time = null
        this.setTime = null
      },
      CancelPayment(){
        clearInterval(this.time)
        clearTimeout(this.setTime)
        this.time = null
        this.setTime = null
        this.$message({
          message: "用户取消支付",
          type: "info",
          offset: 100,
        });
        this.payNow = '立即支付'
        this.submit = true
        this.dialogVisible = false
      },
      paySuc () {
        this.payNow = '支付中...'
        this.submit = false
        if (this.payType === 1) {
          this.type = 'Alipay'
        } else if (this.payType === 2) {
          this.type = 'Wechat'
        } else if (this.payType === 3) {
          this.type = 'QQ'
        } else {
          this.type = '其它'
        }
        console.log("this.form", this.form);
        let headers = {}
        axios({
        method: 'POST',
        baseURL:'https://www.xiaoguobx.com/prod-api',
        url: '/XGShopping/nativePay', // 替换为你的后端API地址
        data:this.form,
        headers,
        responseType: 'arraybuffer' // 重要：设置响应类型为arraybuffer以接收二进制数据
        }).then(res =>{
          const blob = new Blob([res.data], { type: 'image/png' });
          this.qrcodeSrc = URL.createObjectURL(blob);
          this.dialogVisible = true
          this.time = setInterval(() => {
            // 判断是否支付成功
            console.log("this.form", this.form);
            selectNativePay(this.form.orderid).then((res) => {
              if (res.data.type == 'success') {
                this.time && this.clearTimer() //清除定时器
                this.dialogVisible = false
                this.$router.push({ path: '/financialInfo' })
              }
            })
          }, 5000)
          // 5分钟后关闭模态框、清除定时器
          this.setTime = setTimeout(() => {
            this.clearTimer()
            this.dialogVisible = false
          }, 5 * 60 * 1000)
        })
      },
      isMoney (v) {
        if (v < 0.1) {
          return false
        }
        var regu = /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/
        var re = new RegExp(regu)
        if (re.test(v)) {
          return true
        } else {
          return false
        }
      },
      isEmail (v) {
        var regu = /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/
        var re = new RegExp(regu)
        if (re.test(v)) {
          return true
        } else {
          return false
        }
      }
    },
    created () {
      // this.userId = getStore('userId')
      this.form = JSON.parse(this.$route.query.data)
      if (this.form) {
        this._getOrderDet(this.form)
      } else {
        this.$router.push({path: '/'})
      }
    },
    components: {
      YShelf,
      YButton
    }
  }
</script>
<style lang="scss" scoped rel="stylesheet/scss">
@import "../../assets/style/index";
.pay-dialog-body p{
    margin-bottom: 10px;
}
.pay-for-method-1{
    font-weight: bold;
    color: #67c23a;
}
    .qrcode-dialog .el-dialog__body {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
    
    .qrcode-container {
      text-align: center;
    }
    
    .qrcode-image {
      max-width: 240px;
      max-height: 240px;
    }
  .w {
    padding-top: 39px;
  }

  .order-info {
    padding: 60px 0 55px;
    color: #333;
    background: #fff !important;
    h3 {
      padding-bottom: 14px;
      line-height: 36px;
      text-align: center;
      font-size: 36px;
      color: #212121;
    }
    .payment-detail {
      text-align: center;
      line-height: 24px;
      font-size: 14px;
      color: #999;
    }
  }

  /*支付类型*/
  .pay-type {
    margin: 0 auto;
    width: 90%;
    padding-bottom: 30px;
    .p-title {
      font-size: 18px;
      line-height: 40px;
      padding: 0 10px;
      position: relative;
      &:before {
        content: ' ';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        border-bottom: 1px solid #ccc;
      }
    }

  }

  .pay-type {
    .pay-item {
      display: flex;
      align-items: center;
      div {
        margin-top: 20px;
        width: 175px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #E5E5E5;
        cursor: pointer;
        border-radius: 6px;
        margin-right: 10px;
        background: #FAFAFA;
        &.active {
          border-color: #6A8FE5;
          background: #fff;
        }
        img {
          display: block;
          height: 34px;
          margin: 8px auto;
        }
      }
    }
  }

  .box-inner {
    line-height: 60px;
    background: #f9f9f9;
    border-top: 1px solid #e5e5e5;
    box-sizing: border-box;
    > div {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 0 20px;
    }
    em {
      margin-left: 5px;
      font-size: 24px;
      color: #d44d44;
      font-weight: 700;
      margin-right: 20px;
      span {
        margin-right: 4px;
        font-size: 16px;

      }
    }
  }

  .confirm-detail {
    padding: 0 30px 25px;
    border-top: 1px solid #d5d5d5;
    .info-title {
      height: 14px;
      margin: 30px 0 17px;
      line-height: 14px;
      font-weight: bolder;
      color: #333;
    }
    .info-detail {
      line-height: 24px;
      color: #666;
    }
  }

  .confirm-table-title {
    padding: 3px 0 0 33px;
    border-top: 1px solid #D5D5D5;
    line-height: 54px;
    font-weight: bolder;
    color: #000;
    display: flex;
    justify-content: space-between;
    span {
      display: inline-block;
      width: 175px;
      text-align: left;
    }
    .price {
      padding-left: 80px;
    }
    .num {
      padding-left: 75px;
    }
    .subtotal {
      padding-left: 72px;
    }
  }

  .confirm-goods-table {
    border-top: 1px solid #D5D5D5;
    .cart-items {
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      a {
        color: #333;
      }
    }
    .n-b {
      display: flex;
      align-items: center;
      justify-content: center;
      > div {
        color: #626262;
        font-weight: 700;
        width: 175px;
        text-align: center;
      }
    }
  }

  .order-discount-line {
    padding: 22px 30px 53px;
    border-top: 1px solid #D5D5D5;
    line-height: 24px;
    text-align: right;
    font-size: 12px;
    &:first-child {
      line-height: 32px;
      text-align: right;
      font-size: 14px;
      font-weight: bolder;
    }
  }

  .name {
    width: 40%;
  }

  .name-cell {
    padding-left: 33px;
  }

  .input {
    width:30%;
    margin:0 0 1vw 38px;
  }

  .pay-info {
    margin-top: -2vw;
    text-align: center;
  }

  .money-select {
    width: 31%;
    padding-left: 10px;
    margin-bottom: 1vw;
  }
</style>
